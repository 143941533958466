.bodybase{
    background: #fff;
}

.escolhaprodutoimg{
    border: 1px;
    border-color: black;
    border: solid 2px black; 
    width: 320px;
    margin-top: 20px;
    margin-left: 15px;
}

.escolhaproduto{
    display: flex;
    flex-wrap: wrap;
}

.escolhaprodutotitulo{
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    width: 100%;
}
