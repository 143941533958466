.bodybase{
    background: #fff;
}

.divbuscamain{
    display: flex;
    flex-wrap: wrap;
}

.projdiv{
    display: flex;
}

.projwrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.projdata{
    margin-top: 5px;
    height: 25px;
    font-size: medium;
}

.projtitulo{
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
}


.projlabels{
    margin-top: 10px;
    margin-left: 10px;
    font-size: medium;
    font-weight: bold;
    margin-right: 10px;
    min-width: 90px;
}

.projlabels2{
    margin-top: 15px;
    margin-left: 10px;
    font-size: medium;
    font-weight: bold;
    margin-right: 10px;
    min-width: 90px;
}

.projlabels3{
    margin-top: 20px;
    margin-left: 10px;
    font-size: medium;
    font-weight: bold;
    margin-right: 0px;
    min-width: 90px;
}

.projtab{
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: .75rem;
    text-align: left;
    border-collapse: collapse;
    color: #000;
    border: 1px solid #000;
    background-color: rgb(234, 239, 247);
}

.pivoted{
    padding: .85rem;
    border-collapse: collapse;
    border-top: 1px solid #000;
    color: #000;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #fff;
    border-radius: 0px;
}

.icones{
    margin-left: 5px;
    margin-right: 5px;
}

.btinvisivel{
    background-color: transparent;
    border: none;
}

.projinputs{
    width: 200px;
    height: 15px;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 15px;
    margin-right: 0px;
    display: flex;
    border-radius: 5px;
    align-self: center;
    justify-self: center;
    background: #fff;
}