@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.form{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
}

.titulo{
    align-self: center;
    justify-self: center;
    color: #005aab;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    font-style: italic;
    background: #fff;
}

.logo{
    width: 320px;
    height: 320px;
    align-self: center;
    justify-self: center;
    margin-bottom: 20px;
    background: #fff;
}
