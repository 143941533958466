.cadform{
    height: 100%;
    width: 100%;
    margin-top: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #005aab;
    margin-bottom: 7px;
}

.cadquad{
    height: 700px;
    width: 400px;
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    justify-content: center;
}

.cadconcluidoquad{
    height: 600px;
    width: 600px;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    justify-content: center;
}

.cadinputs{
    width: 300px;
    height: 8px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    border-radius: 5px;
    align-self: center;
    justify-self: center;
    background: #fff;
}

.caddivselect{
    margin-left: 25px;
    margin-right: 0;
    display: flex;
    align-self: left;
    justify-self: left;
    background: #fff;
}

.cadbtn{
    width: 318px;
    height: 40px;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    background: #005aab;
    color: #fff;
    font-weight: bold;
    align-self: center;
    justify-self: center;
}

.cadbtn2{
    width: 300px;
    height: 70px;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    background: #005aab;
    color: #fff;
    font-weight: bold;
    align-self: center;
    justify-self: center;
    font-size: medium;
}

.cadtitulo{
    align-self: center;
    justify-self: center;
    background: #fff;
}

.cadlabels{
    margin-left: 20px;
    width: 90%;
    display: flex;
    background: #fff;
    text-align: center;
}

.cadcadastro{
    margin-left: 20px;
    background: #fff;
    display: inline-flex;
}

.cadlink{
    margin-left: 5px;
    background: transparent;
    color: #fff;
}


/* abaixo foi necessario sobrescrever o background do componente combobox */
._r3jpc{
    background: #fff;
    background-color: #fff;
}

._2iQTD{
    background: #fff;
    background-color: #fff;
}

.retlabels{
    margin: 0 auto;
    width: 90%;
    background: #fff;
    color: red;
}