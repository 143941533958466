.select-button {
    position: relative;
    width: 250px;
    margin-bottom: 30px;
    display: inline-block;
  }
  
  .select-button input {
    position:absolute;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    color: #fff;
    background: #005aab;
    font-size: 1em;
    padding: 0.25em 1em;
    border: 2px solid #005aab;
    border-radius: 3px;
    height: 30px;
  }
  
  .select-button ul {
    position:absolute;
    left: 0;
    width: 87%;
    list-style: none;
    display: block;
    z-index: 999;
    color: #fff;
    background: #3990db;
    font-size: 1em;
    margin: 0px;
    padding: 0;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    bottom: -10px;
  }
  
  .select-button ul li {
    padding: 8px;
    cursor: pointer;
  }
  
  .select-button ul li:hover {
    background-color: #005aab;
  }
  
  .select-button:hover ul {
    position:absolute;
    display: block;
    margin-left: 15px;
  }
  