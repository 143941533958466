.bodybase{
    background: #fff;
}

.novoprojetomain{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.novoprojetomainbody{
    flex-wrap: wrap;
}

.novoprojetolabels{
    margin-top: 15px;
    margin-left: 10px;
}

.novoprojetolabels2{
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 0px;
    width: 90%;
    color: blue;
}

.novoprojetoinputs{
    width: 300px;
    height: 8px;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 15px;
    margin-right: 0px;
    display: flex;
    border-radius: 5px;
    align-self: center;
    justify-self: center;
    background: #fff;
}

.novoprojetoinputsnum{
    width: 110px;
    height: 8px;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 15px;
    margin-right: 0px;
    display: flex;
    border-radius: 5px;
    align-self: center;
    justify-self: center;
    background: #fff;
}

.novoprojetotitulo{
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    width: 100%;
}

.novoprojetoinputsobs{
    width: 350px;
    height: 40px;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 15px;
    margin-right: 0px;
    display: flex;
    border-radius: 5px;
    align-self: center;
    justify-self: center;
    background: #fff;
}

.novoprojetoalerta{
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 15px;
    width: 100%;
    color: red;
}

.novoprojetotitulo2{
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    width: 100%;
    color: red;
}

.novoprojetotab{
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: .75rem;
    text-align: left;
    border-collapse: collapse;
    color: #000;
    border: 1px solid #000;
    background-color: rgb(234, 239, 247);
    min-width: 85%;
}


