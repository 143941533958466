.loginform{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #005aab;
}

.loginquad{
    height: 500px;
    width: 400px;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
}

.logininputs{
    width: 280px;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    border-radius: 5px;
    align-self: center;
    justify-self: center;
    background: #fff;
}

.logininputs2{
    width: 280px;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    border-radius: 5px;
    align-self: center;
    justify-self: center;
    background: #fff;
}

.loginbtn{
    width: 318px;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    background: #005aab;
    color: #fff;
    font-weight: bold;
    align-self: center;
    justify-self: center;
}

.logintitulo{
    align-self: center;
    justify-self: center;
    background: #fff;
}

.logintitulo2{
    align-self: center;
    justify-self: center;
    background: #fff;
    margin-top: 15px;
    margin-bottom: 15px;
}

.loginlabels{
    margin-left: 20px;
    background: #fff;
}

.loginlogo{
    width: 120px;
    height: 120px;
    align-self: center;
    justify-self: center;
    margin-bottom: 20px;
    background: #fff;
}

.logincadastro{
    margin-left: 20px;
    background: #fff;
    display: inline-flex;
}

.loginlink{
    margin-left: 5px;
    background: #fff;
}

.recsenhalink{
    margin-right: 40px;
    text-align: right;
    background: #fff;
}

.retlabelslogin{
    margin-left: 0px;
    width: 280px;
    display: flex;
    background: #fff;
    color: red;
    align-self: center;
    text-align: center;
}

.retlabelsloginb{
    margin-left: 0px;
    width: 280px;
    display: flex;
    background: #fff;
    color: green;
    align-self: center;
    text-align: center;
}