.bodybase{
    background: #fff;
}

.meusdadosmain{
    display: flex;
    flex-wrap: wrap;
}

.meusdadosmainbody{
    flex-wrap: wrap;
}

.meusdadoslabels{
    margin-top: 15px;
    margin-left: 10px;
}

.meusdadoslabels2{
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 0px;
    width: 90%;
    color: blue;
}

.meusdadosinputs{
    width: 300px;
    height: 8px;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 15px;
    margin-right: 0px;
    display: flex;
    border-radius: 5px;
    align-self: center;
    justify-self: center;
    background: #fff;
}

.meusdadostitulo{
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    width: 100%;
}

.meusdadosimagem{
    width: 120px;
    height: 120px;
    padding: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 0px;
    display: flex;
    align-self: center;
    justify-self: center;
}

.upload__image-wrapper{
    margin-left: 15px;
}
