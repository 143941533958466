.select-custom {
    position: relative;
    width: 250px;
  }
  
  .select-custom input {
    width: 80%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 15px;
    margin-right: 0px;
    display: flex;
    background-color: #fff;
  }
  
  .select-custom ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 85%;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    display: none;
    z-index: 999;
  }
  
  .select-custom ul li {
    padding: 8px;
    cursor: pointer;
  }
  
  .select-custom ul li:hover {
    background-color: #f0f0f0;
  }
  
  .select-custom:hover ul {
    display: block;
    margin-left: 15px;
  }
  